/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation({
          equalizer : {
            // Specify if Equalizer should make elements equal height once they become stacked.
            equalize_on_stack: true,
            // Allow equalizer to resize hidden elements
            act_on_hidden_el: false
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_talpa': {
      init: function() {
          var swiper = '';
          var swiper2 = '';
          if($('.first-swiper .swiper-container').length) {
              var swiper = new Swiper('.first-swiper .swiper-container', {
                  pagination: '.first-swiper .swiper-pagination',
                  nextButton: '.first-swiper .swiper-button-next',
                  prevButton: '.first-swiper .swiper-button-prev',
                  slidesPerView: 3,
                  paginationClickable: true,
                  spaceBetween: 20
              });
          }
          if($('.second-swiper .swiper-container').length) {
              var swiper2 = new Swiper('.second-swiper .swiper-container', {
                  pagination: '.second-swiper .swiper-pagination',
                  nextButton: '.second-swiper .swiper-button-next',
                  prevButton: '.second-swiper .swiper-button-prev',
                  slidesPerView: 3,
                  paginationClickable: true,
                  spaceBetween: 20
              });
          }
          var $resizer = function(){
              var ww = $(window).width();
              if(swiper != '') {
                  if (ww > 1000) swiper.params.slidesPerView = 3;
                  if (ww > 660 && ww <= 1000) swiper.params.slidesPerView = 2;
                  if (ww <= 660) swiper.params.slidesPerView = 1;
              }
              if(swiper2 != '') {
                  if (ww > 1000) swiper2.params.slidesPerView = 3;
                  if (ww > 660 && ww <= 1000) swiper2.params.slidesPerView = 2;
                  if (ww <= 660) swiper2.params.slidesPerView = 1;
              }
              console.log(true);
          };
          $(window).resize($resizer);
          setTimeout(function(){
              $(window).trigger('resize');
          }, 300);
          $resizer();

          $(document).on('opened.fndtn.reveal', '[data-reveal]', function () {
              var modal = $(this);
              var $oldImage = $(this).find('.small-images li:first-child').find('img').attr('src');
              $(this).find('.main-image').attr('src', $oldImage);
              $('.reveal-modal.open .small-images li img').on('mouseenter', function () {
                  var $newSrc = $(this).attr('src');
                  $oldImage = $('.main-image').attr('src');
                  $('.main-image').attr('src', $newSrc);
              }).on('mouseleave', function () {
                  if($oldImage != '') {
                      $('.main-image').attr('src', $oldImage);
                  }
              });
              $('.reveal-modal.open .small-images li img').on('click',function(){
                  $oldImage = '';
              });

              $('.back-close.right').on('click', function(){
                  var $oldImage = '';
                  $('.close-reveal-modal').trigger('click');
              });
          });
      }
    },
    'page_template_template_contacts': {
      init: function() {
          var mapData = $('#map');
          var myLatLng = {lat: parseFloat(mapData.attr('data-lat')), lng: parseFloat(mapData.attr('data-lng'))};

          var map = new google.maps.Map(document.getElementById('map'), {
              zoom: 10,
              center: myLatLng,
              scrollwheel: false
          });

          var marker = new google.maps.Marker({
              position: myLatLng,
              map: map
          });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
